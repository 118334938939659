import { combineReducers } from "@reduxjs/toolkit"
import { authReducer } from "./authReducer"
import { truongReducer } from "./truongReducer"
import { khaoSatReducer } from "./khaoSatReducer"
import { formKhaoSatReducer } from "./formKhaoSatReducer"
import { formKhaoSatEditorReducer } from "./formKhaoSatEditorReducer"
import { formKhaoSatItemReducer } from "./formKhaoSatItemReducer"
import { yeuCauHoTroReducer } from "./yeuCauHoTroReducer"
import { baoCaoChiTieuTuyenSinhReducer } from "./baoCaoChiTieuTuyenSinhReducer"
export const reducers = combineReducers({
    auth: authReducer,
    truong: truongReducer,
    khaoSat: khaoSatReducer,
    formKhaoSat: formKhaoSatReducer,
    formKhaoSatEditor: formKhaoSatEditorReducer,
    formKhaoSatItem: formKhaoSatItemReducer,
    yeuCauHoTro: yeuCauHoTroReducer,
    baoCaoChiTieuTuyenSinh: baoCaoChiTieuTuyenSinhReducer
})