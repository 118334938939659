
import { showNotify } from '../../helpers/toast';
import { pageBaseStatus } from "../pageBaseStatus";
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
const iniState = {
    status: pageBaseStatus.is_not_initialization,
    ts_baocao_chitieutuyensinhs: [],
    ts_baocao_chitieutuyensinh_detail: [],
    filter: {
        nam_hoc: "2024-2025",
        dm_truong_id: 0,
    }
}
export const baoCaoChiTieuTuyenSinhActionTypes = {
    LOAD_SELECT: actionNameCreator.BAOCAOCHITIEUTUYENSINH("LOAD_SELECT"),
    LOAD_DETAIL: actionNameCreator.BAOCAOCHITIEUTUYENSINH("LOAD_DETAIL"),
    UPDATE_PHANHOI: actionNameCreator.BAOCAOCHITIEUTUYENSINH("UPDATE_PHANHOI"),
    CHANGE_FILTER: "BAOCAOCHITIEUTUYENSINH_CHANGE_FILTER",
}
export const baoCaoChiTieuTuyenSinhReducer = (state = iniState, action) => {
    switch (action.type) {
        case baoCaoChiTieuTuyenSinhActionTypes.LOAD_SELECT.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
            }
        case baoCaoChiTieuTuyenSinhActionTypes.LOAD_SELECT.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                ts_baocao_chitieutuyensinhs: action.payload
            }
        case baoCaoChiTieuTuyenSinhActionTypes.LOAD_SELECT.ERROR:
            showNotify({ text: action.payload, type: "error" });
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                ts_baocao_chitieutuyensinhs: []
            }
        case baoCaoChiTieuTuyenSinhActionTypes.LOAD_DETAIL.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
            }
        case baoCaoChiTieuTuyenSinhActionTypes.LOAD_DETAIL.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                ts_baocao_chitieutuyensinh_detail: action.payload
            }
        case baoCaoChiTieuTuyenSinhActionTypes.LOAD_DETAIL.ERROR:
            showNotify({ text: action.payload, type: "error" });
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                ts_baocao_chitieutuyensinh_detail: []
            }
        case baoCaoChiTieuTuyenSinhActionTypes.UPDATE_PHANHOI.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
                ts_baocao_chitieutuyensinhs: undefined
            }
        case baoCaoChiTieuTuyenSinhActionTypes.UPDATE_PHANHOI.SUCCESS:
            showNotify({ text: "Thay đổi dữ liệu thành công.", type: "success" });
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                ts_baocao_chitieutuyensinhs: action.payload
            }
        case baoCaoChiTieuTuyenSinhActionTypes.UPDATE_PHANHOI.ERROR:
            showNotify({ text: action.payload, type: "error" });
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                ts_baocao_chitieutuyensinhs: []
            }
        case baoCaoChiTieuTuyenSinhActionTypes.CHANGE_FILTER:
            console.log(action.payload);
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                filter: action.payload
            }
        default:
            return state;
    }
}
