import { pageBaseStatus } from "../pageBaseStatus";
import { showNotify } from '../../helpers/toast';
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
const iniState = {
    status: pageBaseStatus.is_not_initialization,
    sis_form_khaosats: [],
    sis_form_khaosat_selected_ids: [],
    sis_form_khaosat_editing: undefined,
    is_show_confirm: false,
    is_show_modal: false,
    filter:  {
        namHoc: "2023-2024",
        truongSelectedId: 0,
    }
}

export const formKhaoSatActionTypes = {
    LOAD: actionNameCreator.FORMKHAOSAT("LOAD"),
    LOAD_BY_TRUONG: actionNameCreator.FORMKHAOSAT("LOAD_BY_TRUONG"),
    DELETE: actionNameCreator.FORMKHAOSAT("DELETE"),
    SAVE: actionNameCreator.FORMKHAOSAT("SAVE"),
    UPDATE_SELECT_OPTIONS: actionNameCreator.FORMKHAOSAT("UPDATE_SELECT_OPTIONS"),
    SHOW_MODAL: "FORM_KHAO_SAT_SHOW_MODAL",
    CHANGE_DETAIL: "FORM_KHAO_SAT_CHANGE_DETAIL",
    SHOW_CONFIRM: "FORM_KHAO_SAT_SHOW_CONFIRM",
    CHANGE_SELECTED_IDS: "FORM_KHAO_SAT_CHANGE_SELECTED_IDS",
    CLEAR_STATUS: "FORM_KHAO_SAT_CLEAR_STATUS",
    CHANGE_FILTER: "FORM_KHAO_SAT_CHANGE_FILTER",
}
export const formKhaoSatReducer = (state = iniState, action) => {
    switch (action.type) {
        case formKhaoSatActionTypes.LOAD.START:
        case formKhaoSatActionTypes.LOAD_BY_TRUONG.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading
            }
        case formKhaoSatActionTypes.LOAD.SUCCESS:
        case formKhaoSatActionTypes.LOAD_BY_TRUONG.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosats: action.payload
            }
        case formKhaoSatActionTypes.LOAD.ERROR:
        case formKhaoSatActionTypes.LOAD_BY_TRUONG.ERROR:
            showNotify({ type: "error", message: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosats: []
            }
        case formKhaoSatActionTypes.CHANGE_DETAIL:
            return {
                ...state,
                sis_form_khaosat_editing: action.payload
            }
        case formKhaoSatActionTypes.SHOW_MODAL:
            return {
                ...state,
                is_show_modal: action.payload,
                sis_form_khaosat_selected_ids: []
            }
        case formKhaoSatActionTypes.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_form_khaosat_selected_ids: action.payload
            }
        case formKhaoSatActionTypes.SHOW_CONFIRM:
            return {
                ...state,
                is_show_confirm: true
            }
        case formKhaoSatActionTypes.DELETE.START:
            return {
                ...state,
                status: pageBaseStatus.is_deleting
            }
        case formKhaoSatActionTypes.DELETE.SUCCESS:
            showNotify({ type: "success", text: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_confirm: false
            }
        case formKhaoSatActionTypes.DELETE.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_confirm: false
            }


        case formKhaoSatActionTypes.SAVE.START:
            return {
                ...state,
                status: pageBaseStatus.is_saving
            }
        case formKhaoSatActionTypes.SAVE.SUCCESS:
            showNotify({ type: "success", text: "Thay đổi dữ liệu thành công." })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_modal: false,
                sis_form_khaosat_editing: action.payload
            }
        case formKhaoSatActionTypes.SAVE.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_modal: false
            }
        case formKhaoSatActionTypes.CHANGE_FILTER:
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                filter: action.payload
            }
        case formKhaoSatActionTypes.CLEAR_STATUS:
            return {
                ...iniState
            }
        default:
            return state;
    }
}
