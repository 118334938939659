import { pageBaseStatus } from "../pageBaseStatus";
import { showNotify } from '../../helpers/toast';
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
const iniState = {
    status: pageBaseStatus.is_not_initialization,
    sis_form_khaosat_items: [],
    sis_form_khaosat_item_selected_ids: [],
    sis_form_khaosat_item_editing: undefined,
    is_show_confirm: false,
    is_show_modal: false,
    filter: {
        namHoc: "2023-2024",
        truongSelectedId: 0,
    }
}
export const formKhaoSatItemActionTypes = {
    LOAD: actionNameCreator.FORMKHAOSATITEM("LOAD"),
    LOAD_BY_FORM: actionNameCreator.FORMKHAOSATITEM("LOAD_BY_FORM"),
    DELETE: actionNameCreator.FORMKHAOSATITEM("DELETE"),
    SAVE: actionNameCreator.FORMKHAOSATITEM("SAVE"),
    ADD_ITEMS: actionNameCreator.FORMKHAOSATITEM("ADD_ITEMS"),
    ADD_EDITOR: actionNameCreator.FORMKHAOSATITEM("ADD_EDITOR"),
    UPDATE_IDX: actionNameCreator.FORMKHAOSATITEM("UPDATE_IDX"),
    SHOW_MODAL: "FORM_KHAO_SAT_ITEM_SHOW_MODAL",
    CHANGE_DETAIL: "FORM_KHAO_SAT_ITEM_CHANGE_DETAIL",
    SHOW_CONFIRM: "FORM_KHAO_SAT_ITEM_SHOW_CONFIRM",
    CHANGE_SELECTED_IDS: "FORM_KHAO_SAT_ITEM_CHANGE_SELECTED_IDS",
    CLEAR_STATUS: "FORM_KHAO_SAT_ITEM_CLEAR_STATUS",
    CHANGE_FILTER: "FORM_KHAO_SAT_ITEM_CHANGE_FILTER",
}
export const formKhaoSatItemReducer = (state = iniState, action) => {
    switch (action.type) {
        case formKhaoSatItemActionTypes.LOAD.START:
        case formKhaoSatItemActionTypes.LOAD_BY_FORM.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading
            }
        case formKhaoSatItemActionTypes.LOAD.SUCCESS:
        case formKhaoSatItemActionTypes.LOAD_BY_FORM.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosat_items: action.payload
            }
        case formKhaoSatItemActionTypes.LOAD.ERROR:
        case formKhaoSatItemActionTypes.LOAD_BY_FORM.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosat_items: []
            }
        case formKhaoSatItemActionTypes.CHANGE_DETAIL:
            return {
                ...state,
                sis_form_khaosat_item_editing: action.payload
            }
        case formKhaoSatItemActionTypes.SHOW_MODAL:
            return {
                ...state,
                is_show_modal: action.payload,
                sis_form_khaosat_item_selected_ids: []
            }
        case formKhaoSatItemActionTypes.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_form_khaosat_item_selected_ids: action.payload
            }
        case formKhaoSatItemActionTypes.SHOW_CONFIRM:
            return {
                ...state,
                is_show_confirm: true
            }
        case formKhaoSatItemActionTypes.DELETE.START:
            return {
                ...state,
                status: pageBaseStatus.is_deleting
            }
        case formKhaoSatItemActionTypes.DELETE.SUCCESS:
            showNotify({ type: "success", text: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_confirm: false
            }
        case formKhaoSatItemActionTypes.DELETE.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_confirm: false
            }


        case formKhaoSatItemActionTypes.SAVE.START:
            return {
                ...state,
                status: pageBaseStatus.is_saving
            }
        case formKhaoSatItemActionTypes.SAVE.SUCCESS:
            showNotify({ type: "success", text: "Thay đổi dữ liệu thành công." })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case formKhaoSatItemActionTypes.SAVE.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_modal: false
            }
        case formKhaoSatItemActionTypes.ADD_ITEMS.START:
            return {
                ...state,
                status: pageBaseStatus.is_saving
            }
        case formKhaoSatItemActionTypes.ADD_ITEMS.SUCCESS:
            showNotify({ type: "success", text: "Thay đổi dữ liệu thành công." })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case formKhaoSatItemActionTypes.ADD_ITEMS.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_modal: false
            }
        case formKhaoSatItemActionTypes.ADD_EDITOR.START:
            return {
                ...state,
                status: pageBaseStatus.is_saving
            }
        case formKhaoSatItemActionTypes.ADD_EDITOR.SUCCESS:
            showNotify({ type: "success", text: "Thay đổi dữ liệu thành công." })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case formKhaoSatItemActionTypes.ADD_EDITOR.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_modal: false
            }
        case formKhaoSatItemActionTypes.UPDATE_IDX.START:
            return {
                ...state,
                status: pageBaseStatus.is_saving
            }
        case formKhaoSatItemActionTypes.UPDATE_IDX.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
            }
        case formKhaoSatItemActionTypes.UPDATE_IDX.ERROR:
            showNotify({ type: "success", text: "Thay đổi dữ liệu thành công." })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
            }
        case formKhaoSatItemActionTypes.CLEAR_STATUS:
            return {
                ...iniState
            }
        case formKhaoSatItemActionTypes.CHANGE_FILTER:
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                filter: action.payload
            }
        default:
            return state;
    }
}
