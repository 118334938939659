import React from 'react'

export const LoaderPage = () => {
    return (
        <>
            <div className="loader_wrapper" style={{
                height: window.innerHeight - 100,
                display: "flex",
                alignItems: "center",
                width: "100%",
                background: "transparent",
                flexDirection: "column",
                justifyContent: "center"

            }}>
                {/* <img width="60px" src={"./images/dual_ring.svg"} alt="loader" style={{
                    borderRadius: "50%"
                }} /> */}
                {/* <LoadPanel visible={true} /> */}
            </div>
        </>
    )
}

export const LoaderApp = () => {
    return (
        <>
            <div className="loader_wrapper" style={{
                height: window.innerHeight - 100,
                display: "flex",
                alignItems: "center",
                width: "100%",
                background: "transparent",
                flexDirection: "column",
                justifyContent: "center"

            }}>
                <img height="100px" width={"auto"} src={"../../images/BCVN.png"} alt="loader" />
                <div style={{
                    marginTop: 20,
                    width: 200,
                    height: 10
                }}>
                </div>
            </div>
        </>
    )
}

export default LoaderPage