import { pageBaseStatus } from "../pageBaseStatus";
import { showNotify } from '../../helpers/toast';
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
const iniState = {
    status: pageBaseStatus.is_not_initialization,
    sis_khaosats: [],
    sis_khaosat_selected_ids: [],
    sis_khaosat_editing: undefined,
    is_show_confirm: false,
    is_show_modal: false,
    sis_khaosat_results: [],
    sis_form_khaosat_result_details: [],
    ket_qua_khao_sats: [],
    filter: {
        namHoc: "2023-2024",
        truongSelectedId: 0,
    }
}
export const khaoSatActionTypes = {
    LOAD: actionNameCreator.KHAOSAT("LOAD"),
    LOAD_BY_TRUONG: actionNameCreator.KHAOSAT("LOAD_BY_TRUONG"),
    LOAD_RESULT_BY_TRUONG: actionNameCreator.KHAOSAT("LOAD_RESULT_BY_TRUONG"),
    LOAD_KET_QUA_KHAO_SAT: actionNameCreator.KHAOSAT("LOAD_KET_QUA_KHAO_SAT"),
    LOAD_KET_QUA_KHAO_SAT_CHI_TIET: actionNameCreator.KHAOSAT("LOAD_KET_QUA_KHAO_SAT_CHI_TIET"),
    LOAD_RESULT_DETAIL: actionNameCreator.KHAOSAT("LOAD_RESULT_DETAIL"),
    DELETE: actionNameCreator.KHAOSAT("DELETE"),
    SAVE: actionNameCreator.KHAOSAT("SAVE"),
    PUSH_NOTIFY: actionNameCreator.KHAOSAT("PUSH_NOTIFY"),
    SHOW_MODAL: "KHAO_SAT_SHOW_MODAL",
    CHANGE_DETAIL: "KHAO_SAT_CHANGE_DETAIL",
    SHOW_CONFIRM: "KHAO_SAT_SHOW_CONFIRM",
    CHANGE_SELECTED_IDS: "KHAO_SAT_CHANGE_SELECTED_IDS",
    CLEAR_STATUS: "KHAO_SAT_CLEAR_STATUS",
    CHANGE_FILTER: "KHAO_SAT_CHANGE_FILTER",
}
export const khaoSatReducer = (state = iniState, action) => {
    switch (action.type) {
        case khaoSatActionTypes.LOAD.START:
        case khaoSatActionTypes.LOAD_BY_TRUONG.START:
        case khaoSatActionTypes.LOAD_RESULT_BY_TRUONG.START:
        case khaoSatActionTypes.LOAD_RESULT_DETAIL.START:
        case khaoSatActionTypes.LOAD_KET_QUA_KHAO_SAT.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
                ket_qua_khao_sats: []
            }
        case khaoSatActionTypes.LOAD_KET_QUA_KHAO_SAT_CHI_TIET.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading
            }
        case khaoSatActionTypes.LOAD.SUCCESS:
        case khaoSatActionTypes.LOAD_BY_TRUONG.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_khaosats: action.payload
            }
        case khaoSatActionTypes.LOAD_RESULT_BY_TRUONG.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_khaosat_results: action.payload
            }
        case khaoSatActionTypes.LOAD_RESULT_DETAIL.SUCCESS:
        case khaoSatActionTypes.LOAD_KET_QUA_KHAO_SAT_CHI_TIET.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosat_result_details: action.payload
            }
        case khaoSatActionTypes.LOAD_KET_QUA_KHAO_SAT.SUCCESS:
            console.log({
                p: action.payload
            })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
               
                ket_qua_khao_sats: action.payload
            }
        case khaoSatActionTypes.LOAD.ERROR:
        case khaoSatActionTypes.LOAD_BY_TRUONG.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_khaosats: []
            }
        case khaoSatActionTypes.LOAD_RESULT_BY_TRUONG.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_khaosat_results: []
            }
        case khaoSatActionTypes.LOAD_RESULT_DETAIL.ERROR:
        case khaoSatActionTypes.LOAD_KET_QUA_KHAO_SAT_CHI_TIET.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosat_result_details: []
            }
        case khaoSatActionTypes.LOAD_KET_QUA_KHAO_SAT.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                ket_qua_khao_sats: []
            }
        case khaoSatActionTypes.CHANGE_DETAIL:
            return {
                ...state,
                sis_khaosat_editing: action.payload
            }
        case khaoSatActionTypes.SHOW_MODAL:
            return {
                ...state,
                is_show_modal: action.payload,
                sis_khaosat_selected_ids: []
            }
        case khaoSatActionTypes.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_khaosat_selected_ids: action.payload
            }
        case khaoSatActionTypes.SHOW_CONFIRM:
            return {
                ...state,
                is_show_confirm: action.payload
            }
        case khaoSatActionTypes.DELETE.START:
            return {
                ...state,
                status: pageBaseStatus.is_deleting
            }
        case khaoSatActionTypes.DELETE.SUCCESS:
            showNotify({ type: "success", text: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_confirm: false
            }
        case khaoSatActionTypes.DELETE.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_confirm: false
            }


        case khaoSatActionTypes.SAVE.START:
            return {
                ...state,
                status: pageBaseStatus.is_saving
            }
        case khaoSatActionTypes.SAVE.SUCCESS:
            showNotify({ type: "success", text: 'Thay đổi dữ liệu thành công.' })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case khaoSatActionTypes.SAVE.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_modal: false
            }
        case khaoSatActionTypes.PUSH_NOTIFY.START:
            return {
                ...state,
                status: pageBaseStatus.is_saving
            }
        case khaoSatActionTypes.PUSH_NOTIFY.SUCCESS:
            showNotify({ type: "success", text: 'Gửi thông báo thành công.' })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
            }
        case khaoSatActionTypes.PUSH_NOTIFY.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
            }
        case khaoSatActionTypes.CHANGE_FILTER:
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                filter: action.payload
            }
        case khaoSatActionTypes.CLEAR_STATUS:
            return {
                ...iniState
            }
        default:
            return state;
    }
}
