import { pageBaseStatus } from "../pageBaseStatus";
import { showNotify } from '../../helpers/toast';
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
const iniState = {
    status: pageBaseStatus.is_not_initialization,
    sis_form_khaosat_editors: [],
    sis_form_khaosat_editor_selected_ids: [],
    sis_form_khaosat_editor_editing: undefined,
    is_show_confirm: false,
    is_show_modal: false,
    sis_form_khaosat_editor_types: []
}
export const formKhaoSatEditorActionTypes = {
    LOAD: actionNameCreator.FORMKHAOSATEDITOR("LOAD"),
    LOAD_BY_FORM: actionNameCreator.FORMKHAOSATEDITOR("LOAD_BY_FORM"),
    LOAD_EDITOR_TYPE: actionNameCreator.FORMKHAOSATEDITOR("LOAD_EDITOR_TYPE"),
    DELETE: actionNameCreator.FORMKHAOSATEDITOR("DELETE"),
    SAVE: actionNameCreator.FORMKHAOSATEDITOR("SAVE"),
    UPDATE_SELECT_OPTIONS: actionNameCreator.FORMKHAOSATEDITOR("UPDATE_SELECT_OPTIONS"),
    SHOW_MODAL: "FORM_KHAO_SAT_EDITOR_SHOW_MODAL",
    CHANGE_DETAIL: "FORM_KHAO_SAT_EDITOR_CHANGE_DETAIL",
    SHOW_CONFIRM: "FORM_KHAO_SAT_EDITOR_SHOW_CONFIRM",
    CHANGE_SELECTED_IDS: "FORM_KHAO_SAT_EDITOR_CHANGE_SELECTED_IDS",
    CLEAR_STATUS: "FORM_KHAO_SAT_EDITOR_CLEAR_STATUS",
}
export const formKhaoSatEditorReducer = (state = iniState, action) => {
    switch (action.type) {
        case formKhaoSatEditorActionTypes.LOAD.START:
        case formKhaoSatEditorActionTypes.LOAD_BY_FORM.START:
        case formKhaoSatEditorActionTypes.LOAD_EDITOR_TYPE.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading
            }
        case formKhaoSatEditorActionTypes.LOAD.SUCCESS:
        case formKhaoSatEditorActionTypes.LOAD_BY_FORM.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosat_editors: action.payload
            }
        case formKhaoSatEditorActionTypes.LOAD_EDITOR_TYPE.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosat_editor_types: action.payload
            }
        case formKhaoSatEditorActionTypes.LOAD.ERROR:
        case formKhaoSatEditorActionTypes.LOAD_BY_FORM.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosat_editors: []
            }
        case formKhaoSatEditorActionTypes.LOAD_EDITOR_TYPE.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                sis_form_khaosat_editor_types: []
            }
        case formKhaoSatEditorActionTypes.CHANGE_DETAIL:
            return {
                ...state,
                sis_form_khaosat_editor_editing: action.payload
            }
        case formKhaoSatEditorActionTypes.SHOW_MODAL:
            return {
                ...state,
                is_show_modal: action.payload,
                sis_form_khaosat_editor_selected_ids: []
            }
        case formKhaoSatEditorActionTypes.CHANGE_SELECTED_IDS:
            return {
                ...state,
                sis_form_khaosat_editor_selected_ids: action.payload
            }
        case formKhaoSatEditorActionTypes.SHOW_CONFIRM:
            return {
                ...state,
                is_show_confirm: true
            }
        case formKhaoSatEditorActionTypes.DELETE.START:
            return {
                ...state,
                status: pageBaseStatus.is_deleting
            }
        case formKhaoSatEditorActionTypes.DELETE.SUCCESS:
            showNotify({ type: "success", text: 'Xóa dữ liệu thành công.' })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_confirm: false
            }
        case formKhaoSatEditorActionTypes.DELETE.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_confirm: false
            }


        case formKhaoSatEditorActionTypes.SAVE.START:
            return {
                ...state,
                status: pageBaseStatus.is_saving
            }
        case formKhaoSatEditorActionTypes.SAVE.SUCCESS:
            showNotify({ type: "success", text: 'Thay đổi dữ liệu thành công.' })
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                is_show_modal: false
            }
        case formKhaoSatEditorActionTypes.SAVE.ERROR:
            showNotify({ type: "error", text: action.payload })
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                is_show_modal: false
            }

            case formKhaoSatEditorActionTypes.UPDATE_SELECT_OPTIONS.START:
                return {
                    ...state,
                    status: pageBaseStatus.is_saving
                }
            case formKhaoSatEditorActionTypes.UPDATE_SELECT_OPTIONS.SUCCESS:
                return {
                    ...state,
                    status: pageBaseStatus.is_need_reload,
                    is_show_modal: false
                }
            case formKhaoSatEditorActionTypes.UPDATE_SELECT_OPTIONS.ERROR:
            showNotify({ type: "error", text: action.payload })
                return {
                    ...state,
                    status: pageBaseStatus.is_completed,
                    is_show_modal: false
                }
        case formKhaoSatEditorActionTypes.CLEAR_STATUS:
            return {
                ...iniState
            }
        default:
            return state;
    }
}
