
export const actionNameCreator = {
    AUTH: (actionName) => {
        return actionNameCreatorStatus(`AUTH_${actionName}`)
    },
    TRUONG: (actionName) => {
        return actionNameCreatorStatus(`TRUONG_${actionName}`)
    },
    YEUCAUHOTRO: (actionName) => {
        return actionNameCreatorStatus(`YEU_CAU_HO_TRO_${actionName}`)
    },
    KHAOSAT: (actionName) => {
        return actionNameCreatorStatus(`KHAO_SAT_${actionName}`)
    },
    FORMKHAOSAT: (actionName) => {
        return actionNameCreatorStatus(`FORM_KHAO_SAT_${actionName}`)
    },
    FORMKHAOSATEDITOR: (actionName) => {
        return actionNameCreatorStatus(`FORM_KHAO_SAT_EDITOR_${actionName}`)
    },
    FORMKHAOSATITEM: (actionName) => {
        return actionNameCreatorStatus(`FORM_KHAO_SAT_ITEM_${actionName}`)
    },
    BAOCAOCHITIEUTUYENSINH: (actionName) => {
        return actionNameCreatorStatus(`BAOCAO_CHITIEUTUYEN_SINH_${actionName}`)
    },
}
const actionNameCreatorStatus = (actionName) => {
    return {
        START: actionName.toUpperCase() + "_START",
        SUCESS: actionName.toUpperCase() + "_SUCCESS",
        ERROR: actionName.toUpperCase() + "_ERROR",
        SUCCESS: actionName.toUpperCase() + "_SUCCESS"
    };
}