
import { showNotify } from '../../helpers/toast';
import { pageBaseStatus } from "../pageBaseStatus";
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
const iniState = {
    status: pageBaseStatus.is_not_initialization,
    app_yeucauhotros: [],
    app_yeucauhotro_detail: [],
    filter: {
        namHoc: "2023-2024",
        truongSelectedId: -1,
    }
}
export const yeuCauHoTroActionTypes = {
    LOAD_SELECT: actionNameCreator.YEUCAUHOTRO("LOAD_SELECT"),
    LOAD_DETAIL: actionNameCreator.YEUCAUHOTRO("LOAD_DETAIL"),
    UPDATE_PHANHOI: actionNameCreator.YEUCAUHOTRO("UPDATE_PHANHOI"),
    CHANGE_FILTER: "YEU_CAU_HO_TRO_CHANGE_FILTER",
}
export const yeuCauHoTroReducer = (state = iniState, action) => {
    switch (action.type) {
        case yeuCauHoTroActionTypes.LOAD_SELECT.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
            }
        case yeuCauHoTroActionTypes.LOAD_SELECT.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                app_yeucauhotros: action.payload
            }
        case yeuCauHoTroActionTypes.LOAD_SELECT.ERROR:
            showNotify({ text: action.payload, type: "error" });
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                app_yeucauhotros: []
            }
        case yeuCauHoTroActionTypes.LOAD_DETAIL.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
            }
        case yeuCauHoTroActionTypes.LOAD_DETAIL.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                app_yeucauhotro_detail: action.payload
            }
        case yeuCauHoTroActionTypes.LOAD_DETAIL.ERROR:
            showNotify({ text: action.payload, type: "error" });
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                app_yeucauhotro_detail: []
            }
        case yeuCauHoTroActionTypes.UPDATE_PHANHOI.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
                app_yeucauhotros: undefined
            }
        case yeuCauHoTroActionTypes.UPDATE_PHANHOI.SUCCESS:
            showNotify({ text: "Thay đổi dữ liệu thành công.", type: "success" });
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                app_yeucauhotros: action.payload
            }
        case yeuCauHoTroActionTypes.UPDATE_PHANHOI.ERROR:
            showNotify({ text: action.payload, type: "error" });
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                app_yeucauhotros: []
            }
        case yeuCauHoTroActionTypes.CHANGE_FILTER:
            return {
                ...state,
                status: pageBaseStatus.is_need_reload,
                filter: action.payload
            }
        default:
            return state;
    }
}
